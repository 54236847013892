var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sig-cro"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"640"},model:{value:(_vm.imageModal),callback:function ($$v) {_vm.imageModal=$$v},expression:"imageModal"}},[_c('v-card',{staticClass:"sig-cro-dialog__card",attrs:{"drop-padding":true}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("\n        "+_vm._s(_vm.$t('profile.visual_signature.upload.dialog.title'))+"\n      ")]),_vm._v(" "),_c('v-card-text',{staticClass:"pa-0"},[(_vm.uploadedImage)?_c('cropper',{staticClass:"upload-cropper",attrs:{"src":_vm.uploadedImage,"stencil-props":{
            handlers: {
              eastNorth: true,
              north: false,
              westNorth: true,
              west: false,
              westSouth: true,
              south: false,
              eastSouth: true,
              east: false,
            },
          }},on:{"change":_vm.changeStencil}}):_vm._e()],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"x-large":"","block":_vm.$vuetify.breakpoint.xsOnly,"color":"info","outlined":""},on:{"click":_vm.clearLocalImage}},[_vm._v("\n          "+_vm._s(_vm.$t('global.cancel'))+"\n        ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{class:_vm.$vuetify.breakpoint.xsOnly ? 'order-first' : '',attrs:{"x-large":"","color":"info","block":_vm.$vuetify.breakpoint.xsOnly},on:{"click":_vm.uploadImage}},[_vm._v("\n          "+_vm._s(_vm.$t('global.save'))+"\n        ")])],1)],1)],1),_vm._v(" "),_c('v-btn',{staticClass:"sig-cro__upload-btn ml-0",attrs:{"color":"info","outlined":""}},[_c('span',{on:{"click":function($event){_vm.fileInput?.click()}}},[_c('input',{ref:"fileInput",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.loadLocalImage}}),_vm._v(" "),(!_vm.isCustomImage)?[_vm._v(_vm._s(_vm.$t('profile.visual_signature.upload.upload_label')))]:[_vm._v(_vm._s(_vm.$t('profile.visual_signature.upload.reset_label')))]],2)]),_vm._v(" "),(_vm.isCustomImage)?_c('v-btn',{class:_vm.$vuetify.breakpoint.xsOnly ? 'ml-0' : '',attrs:{"color":"error","outlined":""},on:{"click":_vm.deleteImage}},[_vm._v("\n    "+_vm._s(_vm.$t('profile.visual_signature.upload.delete_label'))+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }